// Core
import React from 'react';
import { Link } from "react-router-dom";

// Styles
import './Header.scss';

// Images
import Logo from '../../assets/img/Logo.svg';
import LogoBlue from '../../assets/img/Logo-blue.svg';
import { useLocation } from 'react-router-dom';

const Header = () => {

  const headerLinks = [
    {
      title: 'Work',
      href: '/#work',
    },
    {
      title: 'Services',
      href: '/#services',
    },
    {
      title: 'Company',
      href: '/#about'
    }
  ]

  const useIsNotMainPage = () => {
    const location = useLocation();
    return location.pathname !== '/'
  }

  return (
    <header className={`container-fluid header ${useIsNotMainPage() ? 'header--blue' : ''}`}>
      <div>
        <nav className="top-header navbar navbar-expand-md navbar-light">
          <div className="logo">
            <Link to="/">
              {
                useIsNotMainPage()
                  ? <img src={LogoBlue} alt="logo" />
                  : <img src={Logo} alt="logo" />
              }

            </Link>
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="navbar-nav">
              {headerLinks.map(({ href, title }) => (
                <a key={title}className="link_animation" href={href}>
                  { title}
                </a>
              ))}
              <a href="/#hire-us">Hire&nbsp;Us</a>
            </div>
          </div>
          <div className="header__menu js-menuIcon js-filterOpen d-md-none">
            <span></span>
            <span></span>
            <span></span>
          </div>

        </nav>

        <div className="header-menu js-menu">
          <ul>
            {headerLinks.map(({ href, title }) => (
              <li key={title}>
                <a className="scroll js-mob-scroll link_animation" href={href}>
                  {title}
                </a>
              </li>
            ))}
            <li>
              <a className="scroll js-mob-scroll" href="#hire-us">Hire&nbsp;Us</a>
            </li>
          </ul>

        </div>
      </div>
      {/* <div className="btn-bottom">
        <button id="btnToTop" className="myBtn" title="Go to top">Top</button>
      </div> */}
    </header>
  )
}

export default Header;