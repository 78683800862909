// Core
import React from 'react';

// Styles
import './Footer.scss';

// Constants
import { socialsData } from '../../constants';

const Footer = () => {
  const servicesContent = ['Web Design', 'Web Shop', 'Logo Design', 'Ecxhange Service', 'SaaS Platform', 'Landingpage', 'Other'];

  const budgetContent = ['5-10k', '10-50k', 'more than 50k'];

  const linksContent = [
    {
      title: 'contacts',
      email: 'hello@dib.company',
      phone: '+380 66 605 91 42'
    },
    {
      title: 'Sales manager',
      email: 'sale@dib.company',
      phone: '+380 50 605 91 25'
    },
    {
      title: 'Support',
      email: 'help@dib.company',
      phone: '+380 97 605 45 24'
    },
  ]

  return (
    <footer className="footer container-fluid container__indent" id="bottom">
      <div className="row">
        <div className="col-md-9">
          <div>
            <h5 className="mt-5">Hire us</h5>
            <h2>A project with Dnipro
                                 Innovation Company</h2>
          </div>
          <div>
            <h6 className="mt-5">Services</h6>
            {
              servicesContent.map(item => (
                <button key={item} className="button__services">{item}</button>
              ))
            }
          </div>
          <div className="pb-3">
            <h6 className="mt-5">Budget</h6>
            {
              budgetContent.map(item => (
                <button key={item} className="button__services">{item}</button>
              ))
            }
          </div>
          <div className="mt-5">
            <form className="form">
              <div className="row">
                <div className="col-md-5">
                  <input id="nameSecond" type="text" name="user-name" placeholder="Name" />
                  <span id="nameSecondError" className="error" aria-hidden="true"> Please, enter Name </span>
                </div>
                <div className="col-md-5">
                  <input id="emailSecond" type="email" name="user-email" placeholder="Email" />
                  <span id="emailSecondError" className="error" aria-hidden="true"> Please, enter Email </span>
                </div>
                <div className="col-md-12">
                  <textarea name="textarea" placeholder="Project details" rows="1"></textarea>
                </div>
                <div className="col-md-12">
                  <button id="submitSecond" className="form-btn submit" type="submit">Send</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-3">
          {
            linksContent.map(({ title, email, phone }) => (
              <div key={title} className="footer__text">
                <h5>{title}</h5>
                <p>
                  <a href={`mailto:${email}`}>{email}</a>

                  <a href="tel:+380666059142">{phone}</a>
                </p>
              </div>
            ))
          }
          <div className="social-links mb-5">
            <ul className="p-0">
              {
                socialsData.map(({ href, imageBlue, title }) => (
                  <li key={title}>
                    <a href={href}><img src={imageBlue} alt={title} /></a>
                  </li>
                ))
              }
            </ul>
          </div>
          <div>
            <p className="aside-text mb-5">The text and graphic content of the website belongs to Dnipro Innovation Box and cannot be used by other resources without our permission and without the link to the source.</p>
            <p>© Dnipro Innovation Box</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;