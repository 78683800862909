// Core
import React from 'react';

const HireUs = () => {
  return (
    <section id="hire-us" className="hire-us">
        <div className="container-fluid">
          <h5>Hire Us</h5>
          <h2>Leave a Quick Request</h2>
          <form className="form-hire-us">
            <div className="row hire-us__container form__rounded">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-4">
                    <input id="nameFirst" className="form round-start valid-name" type="text" name="name-user" placeholder="Name" />
                    <span id="nameFirstError" className="error" aria-hidden="true"> Please, enter Name </span>
                  </div>
                  <div className="col-md-4">
                    <input id="emailFirst" className="form valid-email" type="email" name="email-user" placeholder="Email" />
                    <span id="emailFirstError" className="error" aria-hidden="true"> Please, enter Email </span>
                  </div>
                  <div className="col-md-4">
                    <input id="phone" className="form round-end valid-phone" type="tel" name="tel-user" placeholder="Phone" />
                    <span id="phoneError" className="error" aria-hidden="true"> Please, enter Phone in  +3 (800) 000-00-00</span>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <input id="submitFirst" className="btn-style submit__rounded submit" type="submit" value="Send" />
              </div>

            </div>
          </form>
          <p><a href="/#bottom" className="link_animation_arrow">or describe your project in detail
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </a>

          </p>
        </div>
      </section>
  )
}

export default HireUs;