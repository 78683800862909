// Core
import React from 'react';

// Components
import MainBannerrr from '../../MainBanner';
import Services from '../../Services';
import Projects from '../../Projects';
import Steps from '../../Steps';
import HireUs from '../../HireUs';
import About from '../../About';
import Team from '../../Team';
import Carrer from '../../Carrer';

const Home = () => {
  return (
    <>
      <MainBannerrr />
      <Services />
      <Projects limit={6} showAllBtn title='Works' subTitle='Projects we are proud of' />
      <Steps />
      <HireUs />
      <About />
      <Team />
      <Carrer />
    </>
  )
}

export default Home;