// Core
import React from 'react';

const VacantionArticle = () => {
  return (
    <>
      <h2>JavaScript Developer</h2>
      <p><b>Development,</b>&nbsp;&nbsp;&nbsp;Dnipro</p>
      <div className="row">
        <div className="col-md-12">
          <p className="text-about">We are proud to work with many great businesses around the globe. We provide software engineering services to achieve their business goals. And now we’re looking for an experienced and delivery-oriented team player with
                                    5+ years experience on management positions. We are looking for a Delivery Manager with strong leadership and communication skills.</p>
        </div>
        <h3>What you’ll help us do:</h3>
        <ul className="responsibilities">
          <li>Define the objectives, delivery unit strategy and associated projects</li>
          <li>Effectively cooperate with customer management to understand business goals</li>
          <li>Manage cross product/project initiatives and improvements (project assessments, SDLC improvements, etc)</li>
          <li>Identify resources required for successful project execution and long term business relationships (Tech Leads, Architects, SPM). Drive resources fulfillment in cooperation with responsible service departments</li>
          <li>Identify all information needed by PM for successful execution of their role and gather and provide such information</li>
          <li>Ensure the internal efficiency of the entrusted business unit</li>
          <li>Engage into and coordinate the pre-sales process and discussion with clients to build the optimal delivery engagement work to standards set on the project while ensuring timely delivery of customer’s business</li>
          <li>Preparation of new bids (technical and commercial proposals for incoming projects)</li>
          <li>Ensure projects are aligned with industry leading quality standards, knowledge sharing teams and clients</li>
          <li>Lead and manage delivery resources, improve project management methodologies</li>
        </ul>
      </div>
    </>
  )
}

export default VacantionArticle;