// Core
import React from 'react';

const ProjectCard = ({ project: { link, image, title, content }, col = 2 }) => {
  
  const columns = 12 / col;

  return (
    <div className={`col-md-${columns} project__card`}>
      <a href={link} target="_blanc">
        <div className="image__zoom">
          <img src={image} alt={`${title}`} />
          <div className="pos-absolute">
            <h3>{title}</h3>
            <p>{content}</p>
          </div>
        </div>
      </a>
    </div>
  )
}

export default ProjectCard;