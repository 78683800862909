// Core
import React from 'react';

// Styles
import './MainBanner.scss';

// Images
import arrowToBottom from '../../assets/img/arrowToBottom.svg'

// Constants
import { socialsData } from "../../constants";

const MainBanner = () => {
  const comp = 'mainBanner';

  return (
    <div className="row">
      <div className={comp}>
        <div>
          <div className="col-md-12">
            <h1 className="maxW-500">Design, Develop, and Support for Your Project</h1>
            <p><a className="link_animation_arrow" href="/#bottom">Let's create something together
                                    <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-right"
                  viewBox="0 0 16 16">
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span></a>
            </p>
          </div>
        </div>
      </div>
      <div className="btn-scroll-down">
        <a className="bottom" href="#bottom"><img src={arrowToBottom} alt="btn-scroll-down" /></a>
      </div>
      <div className="social-menu">
        <ul className="social-links">
          {
            socialsData.map(({ title, image, href }) => (
              <li key={title}>
                <a href={href}><img src={image} alt={title} /></a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default MainBanner;