// Core
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// Pages
import Home from './components/pages/Home';
import Vacations from './components/pages/Vacations';
import ProjectsPage from './components/pages/ProjectsPage';

// Styles
import './assets/styles/index.scss';

// Components
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => (
  <Router>
    <div className="main-container">
      <Header />
      <Switch>
        <Route path="/vacantions">
          <Vacations />
        </Route>
        <Route path="/projects">
          <ProjectsPage />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </div>
  </Router>
);

export default App;
