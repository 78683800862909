// Core
import React from 'react';

// Components
import Projects from '../../Projects';

// Styles
import './ProjectsPage.scss';

// Constants
import { socialsData } from "../../../constants";

const ProjectsPage = () => {
  return (
    <div className="container-fluid">
      <section className="container-fluid projects__list">
        <div className="conteiner__projects-text container__indent">
          <div className="row">
            <div className="col-md-11">
              <h2>Projects we are proud of</h2>
              <p><a href="/#bottom" className="link_animation_arrow">Let's create something together
                    <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                  </svg>
                </span></a>
              </p>
            </div>
            <div className="col-md-1">
              <div className="social-links">
                <ul className="p-0">
                  {
                    socialsData.map(({ title, imageBlue, href }) => (
                      <li key={title} className="mb-15">
                        <a href={href}><img src={imageBlue} alt={title} /></a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Projects col={3} />
      </section>
    </div>
  )
}

export default ProjectsPage;