// Images
//   Projects
import Project1 from './assets/img/projects/img1.png'
import Project2 from './assets/img/projects/img2.png'
import Project3 from './assets/img/projects/img3.png'
import Project4 from './assets/img/projects/img4.png'
import Project5 from './assets/img/projects/img5.png'
import Project6 from './assets/img/projects/img6.png'
import Project7 from './assets/img/projects/img7.png'
import Project8 from './assets/img/projects/img8.png'
import Project9 from './assets/img/projects/img9.png'
import Project10 from './assets/img/projects/img10.png'
import Project11 from './assets/img/projects/img11.png'

// Socials
import FacebookIcon from './assets/img/social/Facebook icon.svg'
import FacebookIconBlue from './assets/img/social/Facebook-blue.svg'
import InstagramIcon from './assets/img/social/Instagram icon.svg'
import InstagramIconBlue from './assets/img/social/Instagram-blue.svg'
import LinkedinIcon from './assets/img/social/LinkedIn icon.svg'
import LinkedinIconBlue from './assets/img/social/LinkedIn-blue.svg'

const socialsData = [
    {
        title: 'Facebook',
        image: FacebookIcon,
        imageBlue: FacebookIconBlue,
        href: 'https://www.facebook.com/DniproInBox',
    },
    {
        title: 'Instagram',
        image: InstagramIcon,
        imageBlue: InstagramIconBlue,
        href: 'https://www.instagram.com/dniproinnovationbox',
    },
    {
        title: 'linkedin',
        image: LinkedinIcon,
        imageBlue: LinkedinIconBlue,
        href: 'https://www.linkedin.com/in/dnipro-innovation-box-859818202/',
    },
]

const projectsData = [
    {
        title: 'Maple Syrup Exchange',
        content: 'Online store for the Maple Syrup and Maple based products',
        image: Project1,
        link: 'https://maplesyrup.exchange/'
    },
    {
        title: "Norm's Marina",
        content: 'Full Service Marina in Hinsdale',
        image: Project2,
        link: 'https://normsmarina.com/'
    },
    {
        title: 'Viking Farm',
        content: 'Independent craft beer and maple syrup distributor in Vermont',
        image: Project3,
        link: 'https://vikings.farm/'
    },
    {
        title: 'Vermont Beer Makers',
        content: 'One of the original 10 breweries in Vermont that have continued operations today',
        image: Project4,
        link: 'https://vermont.beer/'
    },
    {
        title: 'FC Pirin',
        content: 'The homepage of them OFC Pirin and youth football school of Blagoevgrad, Bulgaria',
        image: Project5,
        link: 'https://fcpirin.com/'
    },
    {
        title: 'Belogour Properties',
        content: 'Real estate hub for the Dubai property seekers',
        image: Project6,
        link: 'https://belogour.com/'
    },
    {
        title: 'Vermont Maple Syrop',
        content: 'Here you will find only producta from artisans and farmer in the state of Vermont',
        image: Project7,
        link: 'https://vermontmaplesyrup.com/'
    },
    {
        title: 'Vermont Innovation Box',
        content: 'Creation of the website concept, design, and operational software for the business and its further extension.',
        image: Project8,
        link: 'https://vtinbox.com/'
    },
    {
        title: 'ICWT',
        content: 'A high-quality advertising network for shortly reaching commercial success.',
        image: Project9,
        link: 'https://icwt.io/'
    },
    {
        title: 'InvestBox of Vermont',
        content: 'The InvestBox platform allows individuals to help create and invest in an industry that you are passionate about.',
        image: Project10,
        link: 'https://inboxvt.com/'
    },
    {
        title: 'BOSS Market',
        content: 'Online platform where you can place goods and buy them',
        image: Project11,
        link: 'http://bossmarket.com/'
    },

]

export {
    socialsData,
    projectsData,
}