// Core
import React from 'react';
// import { useLocation } from 'react-router-dom';

// Components
import VacantionArticle from '../../VacantionArticle';

// Constants
// import { vacantions } from '../../../constants';

const Vacations = () => {
  // const search = useLocation().search;
  // const [ article, setArticle ] = useState({});
  
  // useEffect(() => {
  //   const name = new URLSearchParams(search).get('id');
  //   console.log(name);
  // }, [])
  
  return (
    <>
      <div className="container-fluid container__indent">
        <div className="row">
          <div className="col-md-8">
            <section className="vocation">
              <h5>Career</h5>
              <VacantionArticle />
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default Vacations;