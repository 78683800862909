// Core
import React from 'react';

// Styles
import './Services.scss';

// Images
import WebIcon from '../../assets/img/services/web.svg'
import MoneyIcon from '../../assets/img/services/money.svg'
import BrushIcon from '../../assets/img/services/brush.svg'
import WorkIcon from '../../assets/img/services/work.svg'
import DeveloperIcon from '../../assets/img/services/developer.svg'

const Services = () => {
  const servicesContent = [
    {
      title: 'Web',
      content: 'Landing Websites eCommerce SaaS Platforms Online Services',
      image: WebIcon,
    },
    {
      title: 'Develop',
      content: 'Frontend Backend IOS Development Android Development Quality Assurance',
      image: DeveloperIcon,
    },
    {
      title: 'Design',
      content: 'Prototyping UI/UX Mobile Apps Logotypes Brand-style',
      image: BrushIcon,
    },
    {
      title: 'Marketing',
      content: 'Analysis & Sales Communication Logistics Fintech, Trading, Bidding Trading Simulation',
      image: MoneyIcon,
    },
    {
      title: 'Service',
      content: 'Server settings Consulting Support',
      image: WorkIcon,
    }
  ]
  return (
    <div className="first-content container-fluid container__indent">
      <div className="row">
        {
          servicesContent.map(({ image, title, content }) => (
            <div key={title} className="col">
              <img src={image} alt={title} />
              <h3>{title}</h3>
              <p className="maxW-180">{content}</p>
            </div>
          ))
        }
      </div>
      <div className="slider-wrapper">
        <div className="slider__onMobile">
          {
            servicesContent.map(({ image, title, content }) => (
              <div key={title} className="slider__itemMobile">
                <div className="drop-shadow">
                  <img src={image} alt={title} />
                  <h3>{title}</h3>
                  <p>{content}</p>
                </div>

              </div>

            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Services;