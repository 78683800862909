// Core
import React from 'react';

// Styles
import './Projects.scss';

// Components
import ProjectCard from '../ProjectCard/index';
import { projectsData } from '../../constants';

const Projects = ({ title, subTitle, col = 2, showAllBtn = false, limit }) => {
  let dataToRender = projectsData;
  if (limit) {
    dataToRender = dataToRender.slice(0, limit);
  }
  return (
    <section id="work" className="works container-fluid">
      {title && <h5>{title}</h5>}
      {subTitle && <h2>{subTitle}</h2>}
      <div className="row">{
        dataToRender.map(project => (
          <ProjectCard key={project.title} project={project} col={col} />
        ))
      }
      </div>
      {showAllBtn && <a href="/projects" className="form-btn projects">Show all projects</a>}
    </section>
  )
}

export default Projects;