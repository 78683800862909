// Core
import React from 'react';
import { useHistory } from "react-router-dom";

// Styles
import './Carrer.scss';

const Carrer = () => {
  let history = useHistory();

  const carrerContent = [
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '1'
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '2',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '3',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '4',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '5',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '6',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '7',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '8',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '9',
    },
    {
      position: 'Development',
      technology: 'JavaScript Developer',
      location: 'Dnipro',
      id: '10',
    },
  ]

  const redirectToLink = (id) => {
    history.push(`/vacantions/?id=${id}`);
  }
  return (
    <div id="carrer" className="carrer container-fluid container__indent">
      <h2>Carrer</h2>
      <div className="row">
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {
                carrerContent.map(({ position, technology, location, id }) => (
                  <tr key={id} className="link_animation_arrow" onClick={() => redirectToLink(id)}>
                    <td>{position}</td>
                    <td>{technology}</td>
                    <td>{location}</td>
                    <td>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </span>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Carrer;