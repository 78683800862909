// Core
import React from 'react';

const About = () => {
  return (
    <section id="about" className="about container-fluid container__indent">
      <h5>About</h5>
      <h2>Company</h2>
      <div className="row">
        <div className="col-md-6">
          <p>As a full-service UX design agency, we work closely with our clients to define, design, and develop transformative user experiences across all platforms and brand's touch points.</p>
        </div>
        <div className="col-md-6">
          <div className="row w-75 m-auto">
            <div className="col-md-4">
              <h3>184</h3>
              <p>Projectcs Created</p>
            </div>
            <div className="col-md-4">
              <h3>18</h3>
              <p>Years in Business</p>
            </div>
            <div className="col-md-4">
              <h3>38</h3>
              <p>Peoples in Team</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About;