// Core
import React from 'react';
import Slider from "react-slick";

//  Images
import teamFoto1 from '../../assets/img/team/team-foto1.png'
import teamFoto2 from '../../assets/img/team/team-foto2.png'
import teamFoto3 from '../../assets/img/team/team-foto3.png'
import teamFoto4 from '../../assets/img/team/team-foto4.png'

import teamFoto1Color from '../../assets/img/team/team-foto1-color.png'
import teamFoto2Color from '../../assets/img/team/team-foto2-color.png'
import teamFoto3Color from '../../assets/img/team/team-foto3-color.png'
import teamFoto4Color from '../../assets/img/team/team-foto4-color.png'

const Team = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    swipeToSlide: true,
  };
  const teamContent = [
    {
      name: 'Svyatoslav Fedorov',
      image: teamFoto1,
      imageColor: teamFoto1Color,
      position: 'Designer'
    },
    {
      name: 'Name Surname',
      image: teamFoto3,
      imageColor: teamFoto3Color,
      position: 'Position'
    },
    {
      name: 'Name Surname',
      image: teamFoto2,
      imageColor: teamFoto2Color,
      position: 'Position'
    },
    {
      name: 'Name Surname',
      image: teamFoto4,
      imageColor: teamFoto4Color,
      position: 'Position'
    },
    {
      name: 'Name Surname',
      image: teamFoto4,
      imageColor: teamFoto4Color,
      position: 'Position'
    }
  ]
  return (
    <section id="our-team" className="our-team container-fluid container__indent">
      <h2>Our team</h2>
      <div className="row">
        <div className="slider-wrapper">
          <div className="slider__team">
            <Slider {...settings}>
              {
                teamContent.map(({ name, image, imageColor, position }) => (
                  <div key={name} className="slider__item">
                    <div className="drop-shadow animation_image">
                      <img className="img-fluid" src={image} alt="people" />
                      <img className="animation_image--next" src={imageColor} alt="people"/>
                      <p className="slider__text"><b>{name}</b>  {position}
                      </p>
                    </div>
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team;