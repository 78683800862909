// Core
import React from 'react';

// Styles
import './Steps.scss';

const Steps = () => {
  const servicesContent = [
    {
      title: 'Web',
      content: 'Dedicated manager works with you directly. We sign Non-Disclosure Agreement. Identify your project requirements and go over the user stories. We provide the estimate and sign the development contract. Dedicated IT team is assigned to your project.',
    },
    {
      title: 'Design',
      content: 'We prepare a scheme and design User Interface for your web project or application. Once you confirm the design, we move over to the development phase.',
    },
    {
      title: 'Development & Testing',
      content: 'Quality Assurance team reviews the functionality and tracks possible bugs. Project manager informs you about the progress and you evaluate the results.',
    },
    {
      title: 'Support',
      content: 'The website or application is ready to be published on a live domain or server. Mobile application is ready to be deployed live on Google Play or Apple. Congratulations – we are going live now.',
    }
  ]
  return (
    <section id="services" className="services container-fluid container__indent">
      <h5>Services</h5>
      <h2>How do we operate</h2>
      <div className="services__block">
        {
          servicesContent.map(({ title, content }, index) => (
            <div key={title} className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <h3>{index + 1}</h3>
                </div>
                <div className="col-md-10">
                  <h3>{title}</h3>
                  <p>{content}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default Steps;